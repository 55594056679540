import React from "react"
import Layout from "../../components/Layout"
import Head from "../../components/Head"
import Stripe from "../../components/Stripe"
import "../../styles/global.css"

export default function blog() {
  return (
    <div className="pageContent">
      <Head />
      <Layout>
        <div className="startAfterNavbar">
          <h1 className="my-4 mx-3">Blog</h1>
        </div>

        <Stripe title="Coming Soon!">
          <p>Currently being worked on 😮</p>
        </Stripe>
      </Layout>
    </div>
  )
}
